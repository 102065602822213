.toaster {
  max-width: 95%;
  width: max-content;
  word-break: break-all;
}
@media screen and (min-width: 500px) {
  .toaster {
    max-width: 837px;
    word-break: break-all;
  }
}

.__floater__arrow {
  bottom: 2px !important;
}